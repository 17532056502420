var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _vm.addModus
          ? _c("h2", [_vm._v("Toevoegen van sublocatie")])
          : _c("h2", [_vm._v("Wijzigen van sublocatie")]),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  attrs: {
                                                    outlined: "",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .validFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "validFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.validFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.validFrom}`,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  attrs: {
                                                    outlined: "",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .validTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "validTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.validTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Omschrijving",
                                                    outlined: "",
                                                    "persistent-placeholder":
                                                      "",
                                                    "error-messages": errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modifiedDescription,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modifiedDescription",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modifiedDescription",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.addModus
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "persistent-placeholder": "",
                                              "hide-details": "auto",
                                              label: "Door",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _setup.selectedData.modifiedBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modifiedBy",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedData.modifiedBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.addModus
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _setup.modifiedDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modifiedDateFormatted =
                                                  $$v
                                              },
                                              expression:
                                                "modifiedDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Sub-locatie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Sub locatie ID",
                                          rules: "numeric",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "INNAX-ID",
                                                    outlined: "",
                                                    "persistent-placeholder":
                                                      "",
                                                    disabled:
                                                      _setup.bagHasSelection,
                                                    "error-messages": errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .locationReference,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "locationReference",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.locationReference",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(_setup.BagSearch, {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.addModus,
                    expression: "addModus",
                  },
                ],
                on: { selected: _setup.selectedBag },
              }),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: {
                                disabled: !_setup.allowSave || _setup.isSaving,
                              },
                              on: {
                                click: function ($event) {
                                  return _setup.onSave()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-checkbox-marked-circle"),
                              ]),
                              _vm._v(" Opslaan "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }